.batch__container{

    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    
    .batch__container-inputs-panel{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 40%;
        // background: skyblue;
     
        @media screen and (max-width:600px) {
            width: 100%;
        }
        .batch__container-inputs{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            .batch__workspace-input-item{
                margin-top: 20px;
                width:400px;
                @media screen and(max-width:1115px) {
                    width:100%;
                }
            }
        }
        
        
    }
    .batch__container-panel{
        
        width: 60%;
        @media screen and(max-width:750px) {
            width:100%;
        }
        .batch__panel-status{
            
            .batch__panel-status-container-items{
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex-wrap: wrap;
                position: relative;
                width: 100%;
                .batch__panel-status-status{
                    position: absolute;
                    top:0;
                    left: 0;
                }
                .batch__panel-status-id{
                    position: absolute;
                    bottom:0;
                    right: 0;
                }
                .batch__panel-status-user{
                    position: absolute;
                    bottom: 0;
                }
                }
                .batch__panel-status-date{
                    position: absolute;
                    top:0;
                    right: 0;
                    width: 100px;
                }
                .batch__panel-status-item{
                    width: 100%;
                    height: 50vh;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;  
                }
                .batch__panel-status-symbology{
                    position: absolute;
                    top:90px;
                    right: 50px;
                    display: flex;
                    flex-direction: column;
                    min-width: 100px;
                    @media screen and (max-width:500px) {
                        display: none;
                    }
                        
                    
                    div{
                        display: flex;
                        justify-content: space-between;
                        margin-top: 5px;
                        span{
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                            box-shadow: var(--box-shadow);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            span{
                                width: 10px;
                                height: 10px;
                                background: #fff;
                            }
                        }
                        h5{
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
    

    .batch__container-dropzone{
        width: 100%;
    }

    .batch__container-files{
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        max-height: 700px;
        overflow-x:hidden;
        overflow-y:auto ;
        -webkit-box-shadow: inset 0px 0px 12px -7px #000000; 
        box-shadow: inset 0px 0px 12px -7px #000000;
        position:relative;
        .batch__item-file-charged{
            margin: 10px;
        }
    }
.batch__container-button{
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.batch__container-start-process{
    margin: 30px 0px 10px 10px;
    display: flex;
    @media screen and (max-width:700px) {
        justify-content: center;
        align-items: center;
    }
        
    

}