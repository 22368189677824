.skeleton {
    opacity: .7;
    animation: skeleton-loading 1s linear infinite alternate;

}
.skeleton__text{
    width: 100%;
    height: .5rem;
    margin-bottom: .25rem;
    border-radius: .125rem;
    &:last-child{
        margin-bottom: 0;
        width: 80%;
    }
}
.skeleton__shape{
    width:100%;
    min-height:24px;
    margin:5px;
    border-radius: .125rem;
    
  

}
@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 70%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
  }
