.task__container-tabs{
    display:flex;
    justify-content:center;
    flex-wrap: wrap;
    width:100%;
    margin-bottom: 10px;
    align-items:baseline;
    box-sizing: border-box;
    background: var(--secondary-color);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    %height-tabs{
        height: 74px !important;

    }
    .task__tab{
        @extend %height-tabs;
    }
}
.task__tab-task-container{

    .task__tab-task-check-section{
        display: flex;
        flex-direction: column;
        padding: 10px;
        .task__tab-task-check-title{
            width: 100%;
        }
        .task__tab-task-check-inputs-section{
            .task__tab-task-check-input-item{
                margin-top: 10px;
                width: 300px;
                @media screen  and(max-width:500px){
                    width: 100%;
                }
            }
        }
    }
    .task__tab-task-check-comment-section{
        margin: 15px 0px 15px 0px;
        width:600px;
        @media screen  and(max-width:750px){
            width: 100%;
        }
    }
    .task__tab-task-check-buttons-section{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-wrap: wrap;
        .task__tab-task-check-button-item{
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
            margin: 10px;
            @media screen {
                
            }
            
        }
    }
}
.task__required-date-extra-time{
    background-color: #4caf50;
    opacity: .8;
    border-radius: 0.5rem;
    text-align: center;
    padding: 0.8rem;
    color: rgb(255, 255, 255);

}
.task__fix-style-table{

    border-radius: 0.5rem;
    text-align: center;
    padding: 0.8rem;
}
.task__required-date-low-time{
    background-color: #ff9800;
    opacity: .8;
    border-radius: 0.5rem;
    text-align: center;
    padding: 0.8rem;
    color: rgb(255, 255, 255);
}
.task__required-date-out-time{
    background-color: #ef5350;
    opacity: .8;
    border-radius: 0.5rem;
    text-align: center;
    padding: 0.8rem;
    color: rgb(255, 255, 255);
}
