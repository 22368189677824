.upload-documents__container-upload{
    width: 100%;
    .upload-documents__container-dropzone{
        width: 100%;
        padding: 30px;
        min-height:10vh;
        display: flex;
        flex-direction: column;
        z-index: 109;
        .upload-documents__container-input{
            .upload-documents__item-input{
                width: 30%;
                @media screen  and(max-width:720px){
                    width: 100%;
                }
            }
        }
        
        
    
    
    }
    .upload-documents__container-document-type{
        padding-top: 30px;
        width: 300px;
        @media screen and(max-width:600px){
            width: 100%;
        }
            
        
    }
    .upload-documents__container-upload-submit{
        padding-left: 30px;
    }
}
.upload-documents__container-form-metadata{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .upload-documents__container-form-metadata-fields{
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .upload-documents__container-button-metadata{
            width: 300px;
            margin-top: 30px;
            display: flex;
            @media screen and(max-width:500px) {
                width: 80%;
            }
        }
    }
}
