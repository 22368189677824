.requests__tab-request-check-section{
    display: flex;
    flex-direction: column;
    padding: 10px;
    .requests__tab-request-check-title{
        width: 100%;
        margin-top: 20px;
    }
    .requests__tab-request-check-inputs-section{
        .requests__tab-request-check-input-item{
            margin-top: 10px;
            width: 300px;
            @media screen  and(max-width:500px){
                width: 100%;
            }
        }
    }
}