.my-documents__container-search{
    
    padding: 5px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    background: var(--secondary-color);
    border-bottom: .1px solid rgba(0, 0, 0, 0.2);
    .my-documents__container-search-form{
        padding: 5px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;
        width: 100%;
        .my-documents__container-change{
            // background: cadetblue;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row-reverse;
            width: 40%;
            @media screen and (max-width:550px){
                width: 100%;
            }
            // .my-documents__container-change-search{
    
            // }
            .my-documents__container-inputs-search{
                width:100%;
                padding: 0px;
                margin: 5px;
            }
        }
    }
    .my-documents__contianer-form-search-metadata{
        width: 100%;
    }

}
.my-documents__table-styles-container{
    // background-color: white;
    border-radius: none;
   
    border-left:.1px solid rgba(0, 0, 0, 0.2) ;
}
.my-documeents__container-folder-table{
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    background: var(--secondary-color);
    
    .my-documents__container-folder{

        margin-top: 4px;
        padding-left: 8px;
        height: 100%;
        width: 25%;
        .my-documents__item-folder-title{
            display: flex;
            h4{
                font-weight: 500;
                margin-right:6px;

            }
        }
    }
    .my-documents__container-table{
        width: 75%;   
    }

    @media screen and(max-width:1000px){
        .my-documents__container-folder{
            width: 100%;
        }
        .my-documents__container-table{
            width: 100%;
        }
    }
}

.my-documents__container-form-search{
    // display: flex;
    // flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 0px 0px 10px 5px;
    .my-documents__title-form-search{
        width: 100%;
    }
    .my-documents__container-inputs-form-search{
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;

        .my-documents__input-form-search{
            padding: 10px;
            width: 33%;
            @media screen and(max-width:800px){
                width: 50%;
            }
            @media screen and(max-width:600px){
                width: 100%;
            }
        }
    }
}

.my-documents__container-form-metadata-fields{
    width: 100%;
    button{
        margin-top: 5px;
        width: 150px
    }
}
#my-documents__title{
    font-weight: 500;
    
}
.my-documents__table-container{
    border-radius: var(--border-radius);
    .my-documents__table{
        th{
            min-width: 150px;
        }
    }
    .my-documents__table-head{
        .my-documents__table-head-cell{
            font-weight: 600 !important;
            color:#fff !important;
        }
        background: #369BFF;
    }
}


.my-documents__container-details-document{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    .my-documents__details-features{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .my-documents__details-card{
            width: 19%;
            position: relative;
            min-height:100px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: var(--secondary-color);
            border-radius: var(--border-radius);
            box-shadow: var(--box-shadow);
            @media screen and (max-width:600px){
                width:100%;
                margin: 5px;
            }
                
            
            svg{
                position: absolute;
                left: 0; 
                right: 0; 
                margin-left: auto; 
                margin-right: auto; 
                color:rgba(0, 0, 0, 0.07);
            }
            p{
                font-weight: 490;
                text-shadow: 1px 0px 1px #CCCCCC, 0px 1px 1px #EEEEEE, 2px 1px 1px #CCCCCC, 1px 2px 1px #EEEEEE, 3px 2px 1px #CCCCCC, 2px 3px 1px #EEEEEE, 4px 3px 1px #CCCCCC, 3px 4px 1px #EEEEEE, 2px 2px 2px rgba(206,89,55,0);
                text-align: center;
            }
        
        }
    }
    .my-documents__details-title{
        background: var(--secondary-color);
        border-radius: var(--border-radius);
        box-shadow: var(--box-shadow);
        padding: 10px;
        width: 100%;
        margin-bottom: 8px;
    }

    %padding-details{
        margin:1%;

    }
    .my-documents__details-document-container{
        @extend %padding-details;
        width: 55%;
        background:var(--secondary-color);
        min-height: 100vh;

        @media screen and (max-width:950px) {
            width: 100%;
            min-height: 115vh;
        }
    }
    .my-documents__details-document-metadata{
        @extend %padding-details;
        width: 41%;
        @media screen and (max-width:950px) {
            width: 100%;
        }
        .my-documents__details-documents-metadata-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            button{
                margin-top: 10px;
            }
            .my-documents__details-documents-select-type-container{
                align-items: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-top: 10px;
                width: 50%;
                @media screen and (max-width:500px) {
                    width: 90%;
                }
            }
        }
    
    }
    .my-documents__details-document-flow{
        width: 100%;
        
        background: var(--secondary-color);
    }
}

.my-documents__container-flow{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items:center;
    #my-documents__flow-button{
        margin-top: 10px;
        margin-right: 8px;
        align-self: flex-end;
    }
    .my-documents__container-flow-check{
        width: 100%;
    }
    .my-documents__container-flow-inputs{
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;
        @media screen and(max-width:700px) {
            flex-direction: column;
        }
        .my-documents__container-flow-input{
            padding: 10px;
            
            &:first-child{
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                width: 100%;
                .my-documents__flow-input{
                    width: 45%;
                    @media screen and(max-width:1200px) {
                        padding: 5px;
                        width: 100%;
                    }
                        
                    
                }
                @media screen and(max-width:900px) {
                    width:40%;
                }
                @media screen and(max-width:700px) {
                    width:100%;
                } 
            }
            &:last-child{
                width: 100%;
                @media screen and(max-width:900px) {
                    width:60%;
                }
                @media screen and(max-width:700px) {
                    width:100%;
                } 
            }
        }
    }
}







.react-autosuggest__container {
    position: relative;
    width: 100%;
    a{
        color: inherit; /* blue colors for links too */
        text-decoration: inherit; /* no underline */
    }
}
.react-autosuggest__input {
    width: 100%;
    height: 30px;
    padding: 25px 25px 25px 10px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 18px;
    border: 1px solid #aaa;
    border-radius: 4px;
    &:focus{
        outline: none;
    }
}


.react-autosuggest__container--open{

    .react-autosuggest__input {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}


.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
    max-height: 400px;
    overflow: auto;
    display: block;
    position: absolute;
    width: 100%;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
    &:focus{
        background: red;
    }
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;

}

.react-autosuggest__suggestion:not(:first-child) {
    border-top: 1px solid #ddd;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }
  
.react-autosuggest__suggestion--focused {
    background-color: #0C7EAF ;
    height: 10px;
    color: #fff;
}

.my-documents__suggestion-content {
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
  

    .my-documents__name {
        margin-left: 10px;
        line-height: 50px;
        position: relative;
        height: 50px;
        width: 100%;
        .my-documents__search-suggestion-date{
            position: absolute;
            top: 10;
            right:5px;
            color: #aaa;
            font-size: 13px;
           
        }
    }
}

.my-documents__highlight {
    color: #00b6ee;
    font-weight: bold;
}
