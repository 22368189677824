


.components-modal__modal-default{
    display: flex;
    justify-content: center;
    align-items: center;
    .components-modal__container-card-title{
        width: 90%;
        padding: 25px 0px 0px 0px;
        position: relative;
        max-height: 100vh;
        overflow: auto;
        .components-modal__title-card{
            display:flex;
            justify-content: center;
            align-items: center;
            align-self: center;
            position: absolute;
            width: 85%;
            background: var(--main-company-color);
            height: 65px;
            z-index: 10;
            top: 0px;
            left:0;
            right:0;
            margin-left: auto;
            margin-right: auto;
            -webkit-box-shadow: 0px 0px 10px -5px #000000; 
            box-shadow: 0px 0px 10px -5px #000000;
            font-weight: 100;
            border-radius: 3px;
            font-weight: 500;
            @media screen and(max-width:1100px) {
                width: 75%;
            }
            @media screen and(max-width:500px) {
                width: 65%;
            }
            p{
                margin-left: 30px;
                font-size: 20px;
            }
        }
        .components-modal__card-title{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items:flex-start;
            position: relative;
            height: 100%;
            background: var(--secondary-color);
            border-radius: var(--border-radius);
       
            .components-modal__card-title-content{
                width: 100%;
                display: flex;
                flex-direction: column;
                padding-top:45px;
            
                .components-modal__modal-default-buttons{
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    width: 100%;
                    padding: 10px;
                }
            }
    
        }
        
    }

}

.components-modal__modal-preview-document{
    display: flex;
    justify-content: center;
    align-items: center;
 
    .components-modal__modal-preview-document-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        width: 95%;
        border-radius: 5px;
        padding: 10px;
        background: var(--secondary-color);
        position: relative;
        min-height: 95vh;
        max-height: 100vh;

        .components-modal__modal-preview-document-title{
            width: 100%;
            height: 40px;
        }
        .components-modal__modal-preview-document-close{    
            position: absolute;
            right:10px;
            top: 5px;
            cursor: pointer;
        }
        .components-modal__modal-preview-content{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            overflow: auto;
            .components-modal__modal-preview-document-item{
                width: 40%;
                height: 10px;
                min-height: 80vh;
 
                @media screen and(max-width:800px){
                    width:100%;
                    flex-direction: column;
                }
                &:last-child{
                    width: 100%;
                    background: green;
                    @media screen and(max-width:800px){
                        width:100%;
                    }
                }
            }
        }
        .components-modal__modal-preview-document-buttons{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            margin-top: 20px;

        }
    }
}



.components-modal__modal-metadata-document{
    display: flex;
    justify-content: center;
    align-items: center;
 
    .components-modal__modal-metadata-document-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        width: 95%;
        border-radius: 5px;
        padding: 10px;
        background: var(--secondary-color);
        position: relative;
        min-height: 95vh;
        max-height: 100vh;

        .components-modal__modal-metadata-document-title{
            width: 100%;
            height: 40px;
        }
        .components-modal__modal-metadata-document-close{    
            position: absolute;
            right:10px;
            top: 5px;
            cursor: pointer;
        }
        .components-modal__modal-metadata-content{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            overflow: auto;
            .components-modal__modal-metadata-document-item{
                width: 50%;
                height: 10px;
                min-height: 80vh;
                .components-modal__metadata-container{
                    display: flex;
                    justify-content: center;
                    
                }
                @media screen and(max-width:800px){
                    width:100%;
                    flex-direction: column;
                }
            
                &:last-child{
                    width: 50%;
                  
                    @media screen and(max-width:800px){
                        width:100%;
                    }
                }
            }
        }
        .components-modal__modal-metadata-document-buttons{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            margin-top: 20px;

        }
    }
}


.components-modal__modal-signature-document{
    display: flex;
    justify-content: center;
    align-items: center;
 
    .components-modal__modal-signature-document-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        width: 95%;
        border-radius: 5px;
        padding: 10px;
        background: var(--secondary-color);
        position: relative;
        min-height: 95vh;
        max-height: 100vh;

        .components-modal__modal-signature-document-title{
            width: 100%;
            height: 40px;
        }
        .components-modal__modal-signature-document-close{    
            position: absolute;
            right:10px;
            top: 5px;
            cursor: pointer;
        }
        .components-modal__modal-signature-content{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            overflow: auto;
            .components-modal__modal-signature-document-item{
                width: 50%;
                height: 10px;
                min-height: 80vh;
                
                @media screen and(max-width:800px){
                    width:100%;
                    flex-direction: column;
                }
            
                &:last-child{
                    width: 50%;
                    min-height: 80vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    text-align: center;
                    @media screen and(max-width:800px){
                        width:100%;
                    }
                    .components-modal__modal-signature-document-title{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        h3{
                            font-weight: 300;
                        }
                        margin-bottom: 10px;
                    }
                    .components-modal__modal-signature-document-item-form{
                        display: flex;
                        width: 100%;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        .components-modal_modal-signature-document-item-form-input{
                            width: 90%;
                            padding: 20px;
                            display:flex;
                            justify-content: center;
                        }
                    }
                }
            }
        }
        .components-modal__modal-signature-document-buttons{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            margin-top: 20px;

        }
    }
}







