.component-profile__profile-container {
    padding: 10px;
    background: var(--secondary-color);
    width: 100%;
    display: flex;

    .profile__container {
        display: flex;
        flex-direction: row;
        width: 100%;

        .container {
            display: flex;
            flex-direction: row;
            width: 100%;

            @media screen and (max-width:1200px) {
                flex-direction: row;
            }

            @media screen and (max-width:700px) {
                flex-direction: column;
            }
        }

        @media screen and (max-width:1200px) {
            flex-direction: column;
        }
    }

    .profile__container-image-profile {
        display: flex;
        flex-wrap: wrap;
        width: 20%;
        justify-content: center;
        align-items: center;
        //background: rgb(138, 212, 40);

        @media screen and (max-width:1200px) {
            width: 100%;
        }

        @media screen and (max-width:700px) {
            width: 100%;
            margin-bottom: 10px;
        }
    }

    .profile__container-image-profile>img {
        width: 100px;
        height: 100px;
        border-radius: 5px;
        border: 2px solid black;
    }

    .profile__container-inputs-panel {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 50%;
        //background: skyblue;
        padding: 15px;

        @media screen and (max-width:1200px) {
            width: 50%;
        }

        @media screen and (max-width:700px) {
            width: 100%;
        }


        .profile__container-inputs {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            width: 100%;

            .profile__container-input-item {
                margin-top: 15px;
                width: 400px;

                @media screen and(max-width:1115px) {
                    width: 100%;
                }
            }

            .profile__input {
                width: 250px;
            }
        }


    }

    .security__container-inputs-panel {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 50%;
        //background: rgb(36, 219, 12);
        padding: 15px;

        @media screen and (max-width:1200px) {
            width: 50%;
        }

        @media screen and (max-width:700px) {
            width: 100%;
        }

        .security__container-inputs {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            width: 100%;

            .security__container-input-item {
                margin-top: 15px;
                margin-bottom: 15px;
                width: 400px;

                @media screen and(max-width:1115px) {
                    width: 100%;
                }
            }

        }

        .security__container-inputs>Button {
            margin-top: 25px;

            @media screen and(max-width:700px) {
                margin-top: 10px;
            }
        }
    }
}