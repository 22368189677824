/* Google Fonts Import Link */
// @import "https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap";
// * {
// 	margin: 0;
// 	padding: 0;
// 	box-sizing: border-box;
// 	font-family: 'Poppins', sans-serif;
// }
.sidebar {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 260px;
	background: #11101d;
	z-index: 200;
	transition: all 0.5s ease;
	.sidebar__logo-details {
		height: 60px;
		width: 100%;
		display: flex;
		text-decoration: none;
		align-items: center;
		.sidebar__logo-img {
            color: var(--secondary-color);
            font-size: 30px;
			width: 60px;
            height: 40px;
            min-width: 78px;
            text-align: center;
            line-height: 50px;
			img{
				max-width: 100%;
				max-height: 100%;

			}

		}
		.sidebar__logo-name{
			font-size: 22px;
			color: #fff;
			font-weight: 600;
			word-spacing: 500px;
			transition: 0.3s ease;
			transition-delay: 0.1s;
		}
	}
	.sidebar__nav-links {
		height: 100%;
		padding: 30px 0 150px 0;
		overflow: auto;
		&::-webkit-scrollbar {
			display: none;
		}
		li {
			position: relative;
			list-style: none;
			
			&:hover {
				background: #1d1b31;
				.sidebar__sub-menu.sidebar__blank {
                    height: 50px;
					top: 50%;
					transform: translateY(0%);

				}
			}
			.sidebar__icon-link {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
			svg {
                min-width: 78px;
                text-align: center;
                color:var(--secondary-color);
                cursor: pointer;
                transition: all 0.3s ease ;
			}
			a {

				display: flex;
				align-items: center;
				text-decoration: none;
				
				.sidebar__link-name {
					text-overflow: ellipsis;
					white-space: nowrap;
					font-size: 15px;
                    height: 50px;
                    line-height: 50px;
					font-weight: 400;
					color: #fff;
					transition:all .3s ease;
				
					
				}
			}
			.sidebar__sub-menu {
				padding: 6px 6px 14px 80px;
				margin-top: -10px;
				background: #1d1b31;
				display: none;
				a {

					color: #fff;
					font-size: 15px;
					padding: 5px 0;
					white-space: nowrap;
					opacity: 0.6;
					transition: all .3s ease;
					&:hover {
						opacity: 1;
					}
				}
				.sidebar__link-name {
					display: none;
				}
			}
			.sidebar__sub-menu.sidebar__blank {
				opacity: 1;
				pointer-events: auto;
				padding: 3px 20px 6px 16px;
				opacity: 0;
				pointer-events: none;
			}
		}
		li.sidebar__show-menu {
			svg.sidebar__arrow {
				transform: rotate(-180deg);
			}
			.sidebar__sub-menu {
				display: block;
				
			}
		}
	}

	.sidebar__profile-details {
		position: fixed;
		bottom: 0;
		width: 260px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #1d1b31;
		padding: 12px 0;
		transition: all 0.5s ease;
		overflow-x: hidden;
		
		.sidebar__profile-content {
			display: flex;
			align-items: center;
			cursor: pointer;
		}
		img {
			height: 52px;
			width: 52px;
			object-fit: cover;
			border-radius: 16px;
			margin: 0 14px 0 12px;
			background: #1d1b31;
			transition: all 0.5s ease;
		}
		.sidebar__name-job{
			cursor: pointer;
		}
		.sidebar__profile-name {
			color: #fff;
			max-width: 145px;
			font-size: 18px;
			font-weight: 500;
			text-overflow: ellipsis;
			white-space: nowrap;
			display: block;
			overflow: hidden
			
		}
		.sidebar__profile-job {
			text-overflow: ellipsis;
			white-space: nowrap;
			color: #fff;
			font-weight: 500;
			white-space: nowrap;
			max-width: 145px;
			font-size: 10px;
			text-overflow: ellipsis;
			white-space: nowrap;
			display: block;
			overflow: hidden
		}
		#sidebar__icon-exit{
			min-width: 38px;
			text-align: center;
			line-height: 30px;
			color:var(--secondary-color);
			cursor: pointer;
			transition: all 0.3s ease ;
		}
	
	}
}
// CUANDO EL SIDEBAR ESTA COLAPSADO/CERRADO
.sidebar.sidebar__close {
	width: 78px;
	
	.sidebar__logo-details {
		.sidebar__logo-name{
			transition-delay: 0s;
			opacity: 0;
			pointer-events: none;
		}
	}

	.sidebar__nav-links {
		overflow: visible;
		li {
			.sidebar__icon-link {
				display: block;
			}
			a {
				
				.sidebar__link-name {
					position: relative;
					opacity: 0;
					pointer-events: none;
					
				}
			}
			.sidebar__sub-menu {
				position: absolute;
				left: 100%;
				top: -10px;
				margin-top: 0;
				padding: 10px 20px;
				border-radius: 0 6px 6px 0;
				opacity: 0;
				display: block;
				pointer-events: none;
				transition: 0s;
				.sidebar__link-name {
					font-size: 18px;
					opacity: 1;
					display: block;
				}
				
			}
			&:hover {
				.sidebar__sub-menu {
					top: 0;
					opacity: 1;
					pointer-events: auto;
					transition: all 0.4s ease;
				}
			}
		}
		svg.sidebar__arrow {
			display: none;
		}
	}
	.sidebar__profile-details {
		background: none;
		width: 78px;
		
		img {
			padding: 10px;
		}
		svg {
	
			display: none;
		}
		.sidebar__profile-name {
			display: none;
			
		}
		.sidebar__profile-job {
			display: none;
		}
	}
	~ {
		.sidebar__home-section {
			left: 78px;
			width: calc(100% - 78px);
		}
		
	}
	
}
.sidebar__home-section.sidebar__close {
	.sidebar__home-section-logout{
		margin-right: 15px;
		color:var(--secondary-color);
		opacity:0;
		transition: all .3 ease;
		// display: none;
	}
}
.sidebar__home-section {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #181824;
    z-index: 200;
	height: 50px;
	left: 260px;
	width: calc(100% - 260px);
	transition: all 0.5s ease;

	.sidebar__home-content {
		height: 50px;
		display: flex;
		align-items: center;
		
		.sidebar__bx-menu {
			
			// color: #11101d;
			color:var(--secondary-color);
			font-size: 30px;
			margin: 0 15px;
			cursor: pointer;
			
		}
		.sidebar__text {
			font-size: 35px;
			margin-left: 8px;
		
			.sidebar__icon-back{
				font-weight: 400;
				font-size: 35px;
				margin-right: 5px;

			}
		}
	}
	.sidebar__home-section-logout{
		left: -15px;
		margin-right: 15px;
		color:var(--secondary-color);
		opacity:1;
		transition: all .3 ease;
		svg{
			cursor: pointer;
		}
	}
}
@media (max-width: 420px) {
	// .sidebar.sidebar__close {
	// 	.sidebar__nav-links {
	// 		li {
	// 			.sidebar__sub-menu {
	// 				display: none;
	// 			}
	// 		}
	// 	}
	// }
}
