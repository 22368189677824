:root{

    --main-color:#11101d;
    --primary-color:#1d1b31;
    --secondary-color:#fff;
    --main-company-color:#ffce00;

    --border-radius:5px;
    // -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.66); 

    --box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.26);
}