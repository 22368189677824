
.document-type__form{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    
    .document-type__form-container-inputs{
        width: 50%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        &:last-child{
            width: 50%;
   
        }
        @media screen  and(max-width:700px){
            width: 100%;
        }   
        .document-type__form-container-input{
            width: 300px;
            padding: 10px;
            @media screen and(max-width:750px) {
                width: 100%;
            }
        }
    }
    .document-type__form-buttons-input{
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin-top: 10px;
    }
}
.document-type__container-table-metadata{
    margin-top: 15px;
}
.document-type__modal-metadata{
    width: 55% !important;
    @media screen and(max-width:850px) {
        width: 90% !important;
    }
}
.document-type__form-metadata-container{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items:center;
    .document-type__form-input-container{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 50%;
        padding: 15px;
        @media screen and(max-width:550px){
            width: 100%;
        }
    }
    .document-type__form-buttons-container{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }
}