.wrapper{
    padding: 10px;
    overflow-x: hidden;
    padding-top: 70px;
    height: 100vh;
    margin-left: 78px;
    background:#E4E9F7;
    &::-webkit-scrollbar{
		width: 9px;
		height: 9px;
		background-color: var(--second-bg-color);
	  }
	  &::-webkit-scrollbar-thumb{
		background-color: var(--main-color);
		border-radius: 10rem;
		border: 1px solid var(--main-bg-color);
	  }
}
// .sidebar.sidebar__close {
//   .wrapper{
//     width: 100% !important;
//     margin-left:0px !important;
//     background: red !important;
//   }
// }
@media only screen and (max-width: 600px) {

    .wrapper {
        margin-left: 78px;
    }
    .sidebar__expand .wrapper {
        margin-left: 0;
    }

}