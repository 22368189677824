.files__container-file-charged{
    width: 450px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    min-height: 60px;
    background-color: #2b2b41;
    font-size: 15px;
    cursor:auto;
    border-radius: 5px;
    color: var(--secondary-color);
    -webkit-box-shadow: 0px 0px 13px -5px #000000; 
    box-shadow: 0px 0px 13px -5px #000000;
    padding: 8px;
    .files__file-charged-item{
        width: 10%;   
        &:nth-child(2){
    
            p{
                max-width: 250px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            width: 65%;
        }
        &:last-child{
          
            width: 25%;
            display: flex;
            justify-content: space-around;
            .files__file-charged-item-icon{
                cursor: pointer;
                height: 40px;
                width: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                background: var(--primary-color);
            }
        }
        @media screen  and(max-width:600px){
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            &:nth-child(2){              
                width: 100%;
            }
            &:last-child{
                width: 50%;
            }
        }
    }
    @media screen  and(max-width:600px){
        justify-content: center;
        align-items: center;
        width: 100%;

                
    }
}

.files__dragdrop-zone{
    margin: 20px 0px 10px 0px;
    z-index: 109;
    cursor: pointer;
    width: 100%;
    outline: none;
    height: 150px;
    background-color:white;
    border: 2px solid var(--main-color) ;
    border-radius: 5px ;
    border-style: dashed;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .files__icons-files{
        font-size: 4rem;
        margin-bottom: 10px;
    }
    @media screen and(max-width:600px) {
        height: 300px;
    }
    h2{
        word-break: break-all;
    }
    p{
        user-select: none;
    }
    &:hover{
        border-color: var(--main-company-color) ;
    }
    &:focus{
        border-color: var(--main-company-color) ;
    }
}