.metadata__container-metadata{
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    .metadata__input-metadata{
        padding: 10px;
        width: 33%;
        @media screen and(max-width:800px){
            width: 50%;
        }
        @media screen and(max-width:600px){
            width: 100%;
        }
    }
    
    .metadata__input-metadata-small-form{
        padding: 10px;
        width: 50%;
        @media screen and(max-width:800px){
            width: 50%;
        }
        @media screen and(max-width:600px){
            width: 100%;
        }
    }


    .metadata__input-skeleton{
        padding: 10px;
        width: 33%;
        @media screen and(max-width:800px){
            width: 50%;
        }
        @media screen and(max-width:600px){
            width: 100%;
        }
    }
    .metadata__input-skeleton-small{
    
        padding: 10px;
        width:50%;
        @media screen and(max-width:800px){
            width: 50%;
        }
        @media screen and(max-width:600px){
            width: 100%;
        }
    }
    
}