.commons__preview-thumbail-container{
    width: 100%;
    height: 100%;
    .commons__preview-thumbail-pdf{
        border: 0px;
        width: 100%;
        height: 100%;
    
    }
}

.commons__history-container{
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    .commons__history{
        min-width: 50%
        
    }
}
.commons__history-content{
    background: var(--secondary-color);
    display: flex;
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    padding: 5px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    h3{
        font-weight: 500;
    }
    // width:100%;
}




.commons__comment-container{
    // max-width: 80%;
    width: 80%;

    @media screen and(max-width:780px){
        width: 100%;
    }
    .commons__comment{
        min-width: 50%;
     
        
    }
}
.commons__comment-content{
    background: var(--secondary-color);
    display: flex;
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    padding: 5px;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    h3{
        font-weight: 500;
        margin-bottom: 10px;
        margin-left: 6px;
    }
    
    p{
        margin-left: 6px;
    }
}
// INICIO SECCION LOADERS
.commons__loaders-backdrop{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 30vh;

}
// FIN SECCION LOADERS