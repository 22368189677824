.administration__tab-container{

    display:flex;
    justify-content:center;
    flex-wrap: wrap;
    width:100%;
    margin-bottom: 10px;
    align-items:baseline;
    box-sizing: border-box;
    background: var(--secondary-color);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    %height-tabs{
        height: 74px !important;

    }
    .administration__tab{
        @extend %height-tabs;
    }
}
.administration__table-styles-container{
    background-color: white;
    border-radius: none;
}
// Seccion modals
.administration__content-modal-organization{
  
    width: 100%;
    padding: 10px;

}
.administration__content-modal-list-detail{
    width: 100%;
    padding: 10px;
}
// Fin seccion modals