.components-card__container-card-stats{
    // Este contendor se usa 
    // para tener el lato correcto que ocuapar el tarjeta
    // width:100%;
    margin: 10px;
    width: 230px;
    height: 156px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 99;
    
    
    .components-card__card-stats{
        z-index: 99;
        width: 100%;
        height: 125px;
        position: relative;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
        display: flex;
        flex-direction: column;
        justify-content:flex-start;
        text-align: end;
        flex-wrap: wrap;
        font-size: 14px;
        background:var(--secondary-color);
        border-radius: 4px;
        .components-card__title-card-stats{
            align-self: flex-end;
            word-break: break-word;
            margin-bottom: 10px;
            padding: 10px 8px 0px 0px;
            width: 50%;
            height: 60px;
            color: #999;
            -webkit-user-select: none; /* Safari */        
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* IE10+/Edge */
            user-select: none; /* Standard */-webkit-user-select: none; /* Safari */        
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* IE10+/Edge */
            user-select: none; /* Standard */
        
        }
        .components-card__value-card-stats{
            padding-right: 8px;
            width: 100%;
            p:first-child{
                
                font-weight: 300;
                font-size: 13px;
                margin-bottom: 2px;
            }
            p:last-child{
                font-weight: 300;
                font-size: 18px;
            }

        }
        .components-card__card-stats-icon-container{
            width: 85px;
            height: 85px;
            border-radius: 4px;
            position: absolute;
            left: 30%;
            margin-left: -50px;
            top: 55%;
            margin-top: -100px;
            display: flex;
            justify-content: center;
            align-items: center;
            -webkit-box-shadow: 0px 0px 13px -5px #000000; 
            box-shadow: 0px 0px 13px -5px #000000;
        
            svg{
                color:var(--secondary-color)
            }
        }
        hr{
            margin-top: 4px;
            background-image: -webkit-gradient(linear,left top,right top,from(transparent),color-stop(#d4dadb),to(transparent));
            background-image: -webkit-linear-gradient(left,transparent,#d4dadb,transparent);
            background-image: -o-linear-gradient(left,transparent,#d4dadb,transparent);
            background-image: linear-gradient(to right,transparent,#d4dadb,transparent);
            box-sizing: content-box;
            border: 0;
            height: 1px;
            max-width: 100%;
            max-width: 100%;
        }
    }
}


.components-card__card-container{
    padding: 10px;
    background:var(--secondary-color);
    box-shadow:rgba(0, 0, 0, 0.12) 0px 1px 4px 1px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px 1px;
    -moz-box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px 1px;
    border-radius: 5px;
    display: flex;
    .components-card__card-title{
        width: 100%;
        h4{
            font-weight: 550;
        }
        hr{
            width:300px;
        }
    }
}
