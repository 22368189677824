.tables__table-detail-container{
    border-radius: var(--border-radius);
    .tables__table-detail{
        th{
            min-width: 100px;
        }
    }
    .tables__table-detail-head{
        .tables__table-detail-head-cell{
            font-weight: 600 !important;
            color:#fff !important;
        }
        background: #369BFF;
    }
}