
.components-field__modal-folder{
    display: flex;
    justify-content: center;
    align-items: center;
    .components-field__container-folder-modal{
        width: 60%;
        max-width: 100%;
        background: var(--secondary-color);
        border-radius: var(--border-radius);
        position: relative;
        max-height: 100vh;

        @media screen and(max-width:1200px) {
            min-width: 95%;
        }
        .components-field__folder-title{
            padding: 10px;
            width: 100%;
            font-weight: 500;
        }
        .components-field__folder-container{
            width: 100%;
            display: flex;
            padding: 5px;
            flex-direction: column;
            justify-content: space-between;
            align-items:flex-end;
            position: relative;
            height: 100%;
            .components-field__folder-accordion{
                // min-width: 400px;
                margin-bottom: 10px;
                padding: 5px;
                @media screen and(max-width:500px){
                    width: 100%;
                }
                .components-field__folder-accordion-content{
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    
                    @media screen and(max-width:500px){
                        flex-wrap: wrap;
                    }
                    .components-field__folder-accordion-input{
                        padding: 5px;
                        @media screen and(max-width:500px){
                            width: 100%;
                        } 
                        button{
                            width: 100%;
                        }
                        
                    }
                }
            }
            .components-field__folder-tree{
                height: 240px;
            }
            .components-field__folder-content{
                width: 100%;
                display: flex;
                justify-content: center;
                padding-left: 40px;
                flex-direction: column;
                padding-top:25px;
                overflow: auto;
                .components-field__modal-default-buttons{
                    display: flex;
                    justify-self: flex-end;
                    align-self: flex-end;
                    justify-content: flex-end;
                    align-items:    flex-end;
                    width: 100%;
                    padding: 10px;
                }
            }
    
        }
        
    }

}